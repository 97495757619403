<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" sm="10" lg="5" xl="4" class="image-container">
                    <div>
            <img src="../assets/illustrations/MiceCloud.png" alt="">
        </div>
      </v-col>
      <v-col cols="12" sm="10" lg="5" xl="5" class="detail">
        <h2>Notre Logiciel MiceCloud</h2>
        <p>MiceCloud est une plateforme logicielle dédiée à la surveillance en temps réel de la santé et de la cognition des sujets d'études. Conçue pour les techniciens de laboratoire, elle permet de stocker et visualiser les données collectées par le système MiceTracking. C'est un outil essentiel pour suivre l'état de santé et le comportement de vos sujets
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

.image-container {
  border-radius: 25px;
  display: flex;
  justify-content: center;
}

.detail {
  padding-left: 50px;
  overflow: auto; /* Allows the content to scroll if it becomes too large */
  align-content: center;
}

.detail div {
  background-color: white;
  border-radius: 15px;
}

summary{
  height: 35px;
  padding: 7px 10px;
}
details {
    overflow: hidden;
    padding-left: 10px;
  }
details[open] p {
    animation: slideDown 0.3s ease-in-out;
  }

  @keyframes slideDown {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

@media (max-width: 960px) {

.detail{
  margin-top: 50px;
}
.detail p{
  margin-top: 15px;
}

.detail div{
  margin-top: 35px;
}

}
</style>

<script>
  export default {
		name: 'App',
		components: {
		},
	};
</script>