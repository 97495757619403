<template>
    <div>
        <navbar/>
        <mice-cloud-image/>
        <mice-cloud-presentation class="mt100"/>
        <mice-cloud-fonctionnalites class="mt100"/>
        <bulle-contact/>
        <foot/>
    </div>
</template>


<script>
import foot from '@/components/Footer.vue'
import navbar from '@/components/NavBar.vue'
import MiceCloudPresentation from '../components/MiceCloudPresentation.vue';
import MiceCloudImage from '../components/MiceCloudImage.vue';
import MiceCloudFonctionnalites from '../components/MiceCloudFonctionnalites.vue';
import BulleContact from '../components/BulleContact.vue';
export default {
    components:{
      foot,
      navbar,
      MiceCloudImage,
      MiceCloudPresentation,
      MiceCloudFonctionnalites,
      BulleContact
    },
    metaInfo: {
        title: 'MiceTracking - MiceCloud, Plateforme logicielle de suivi de santé animale',
        meta: [
            {
                name: 'description',
                content: 'Découvrez la plateforme logicielle de MiceTracking pour un suivi précis et interactif des souris. Analisez l\'ammoniaque, la température, les mouvements, et bien plus pour garantir le bien-être de vos souris'
            },
            {
                name: 'keywords',
                content: 'MiceTracking, plateforme logicielle, MiceCloud, analyse, suivi en temps réel, courbes, export CSV, laboratoire, souris, interaction capteurs/animal'
            }
        ]
    },
}
</script>


<style scoped>
.img-miceapp {
    background-image: url(../images/tablette.jpg);
    background-size: cover;
    width: 100vw;
    height: 100vh;
    margin-bottom: 50px;
    background-repeat: no-repeat;
    text-align: justify;
    position: relative;
    background-position: center;
}

.img-miceapp::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Ajout du filtre opaque noir */
    background-color: rgba(0, 55, 109, 0.33);
}

.text-miceapp {
    position: relative;
    margin-top: 140px;
}

.text-miceapp p {
    width: 60%;
}

.list-ico-m {
    margin-top: 140px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.list-ico-m div {
    text-align: center;
    position: relative;
}

.list-ico-m .ico-caption {
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0s linear 0.3s;
}

.list-ico-m .icon {
    transition: transform 0.3s ease;
    max-width: 160px;
    height: auto;
    width: 100%;
}

.list-ico-m .icon:hover {
    transform: rotateY(180deg);
}

.list-ico-m div:hover .ico-caption {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
}

.ico {
    width: 80%;
}

.démo {
    margin-top: 300px;
}

.utilisation {
    margin:  60px 0px;
    background-color: white;
    padding: 20px 0px;
}

.parag-utilisation {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.parag-utilisation div {
    justify-content: left;
    width: 20%;
}

.parag-utilisation div img {
    width: 20%;
    margin-bottom: 35px;
}

.parag-utilisation div h5 {
    font-family: Roboto, Regular;
    font-size: 25px;
    color: var(--gris);
    margin-bottom: 40px;
    font-weight: 400;
}

.parag-utilisation div p {
    font-family: Roboto, Regular;
    font-size: 18px;
    color: var(--gris);
    font-weight: 300;
}

.ico-secu {
    margin-top: 80px
}

@media (max-width: 1264px) {
.img-miceapp {
    height: 90vh;
}

.text-miceapp {
    margin-top: 20px;
    top: 50px;
}
.text-miceapp p {
    width: 80%;
}

.baton {
    display: none
}

.text-miceapp h1 {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.text-miceapp p {
    width: 100%;
    padding: 5%;
    text-align: justify;
}

.title-utilisation h3 {
    padding: 0 5%;
    margin-bottom: 20px;
    width: 100%;
}
.parag-utilisation {
    display: block;
}

.parag-utilisation div {
    width: 100%;
    padding: 5%;
}
.parag-utilisation div img {
    margin-left: 40%;
    width: 20%;
    margin-bottom: 35px;
}
.parag-utilisation div p {
    text-align: justify;
}

.parag-utilisation div h5 {
    text-align: center;
}

.ico-secu {
    margin-top: 0%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.ico-secu img {
    width: 30%;
}

}
@media (max-width: 767px) {
.text-miceapp p {
    width: 100%;
}
.text-miceapp h1 {
    width: 90%;
    margin-left: 5%;
}
}

</style>
